import PropTypes from 'prop-types';
import PageBlogPropTypes from '../PageBlog/PageBlog.types';
import { ElementLinkPropTypes } from '../ElementLink';
import ModuleFormPropTypes from '../ModuleForm/ModuleFormPropTypes';

export default {
  ...PageBlogPropTypes,
  pdf: PropTypes.shape(ElementLinkPropTypes),
  bottomModules: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(ModuleFormPropTypes)]))
};
