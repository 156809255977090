import React from 'react';
import PropTypes from 'prop-types';
import styles from './Asset.module.scss';
import ErrorBoundary from '../ErrorBoundary';

export const AssetPropTypes = {
  contentType: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

function Asset({ contentType, filename, height, size, title, url, width }) {
  return (
    <ErrorBoundary>
      <div data-testid="Asset" className={styles.asset}>
        <table className="table table-bordered m-0">
          <tbody>
            <tr>
              <th scope="row">contentType</th>
              <td>{contentType}</td>
              <td rowSpan="7">
                <img src={url} alt={title} width="280" height="280" />
                <small className="badge badge-dark">PREVIEW</small>
              </td>
            </tr>
            <tr>
              <th scope="row">filename</th>
              <td>{filename}</td>
            </tr>
            <tr>
              <th scope="row">url</th>
              <td>{url}</td>
            </tr>
            <tr>
              <th scope="row">width</th>
              <td>{width}</td>
            </tr>
            <tr>
              <th scope="row">height</th>
              <td>{height}</td>
            </tr>
            <tr>
              <th scope="row">size</th>
              <td>{size}</td>
            </tr>
            <tr>
              <th scope="row">title</th>
              <td>{title}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ErrorBoundary>
  );
}

Asset.propTypes = AssetPropTypes;

export default Asset;
