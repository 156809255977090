/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import sidekickInit from '../../utils/sidekick/init';
import styles from './PagePerson.module.scss';
import ErrorBoundary from '../ErrorBoundary';
import { RichTextParserPropTypes } from '../RichTextParser';
import { AssetPropTypes } from '../Asset';

const Image = dynamic(() => import('../Image'));
const ElementLink = dynamic(() => import('../ElementLink'));
const RichTextParser = dynamic(() => import('../RichTextParser'));
const TradeMark = dynamic(() => import('../../generics/TradeMark'));

export const PagePersonPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape(AssetPropTypes),
  bio: PropTypes.shape(RichTextParserPropTypes),
  highResImage: PropTypes.shape(AssetPropTypes)
};

function PagePerson({ _id, _contentTypeId, fullName, title, image, bio, highResImage }) {
  const { lang } = useTranslation();
  const { sidekicker } = sidekickInit({ _id, _contentTypeId, fullName });

  return (
    <ErrorBoundary>
      <Head>
        <meta name="content_type" content="pagePerson" />
      </Head>
      <div data-testid="PagePerson" className={styles.person} {...sidekicker('Page Person')}>
        <article className="container-xl container col-md-8 col-xs-12 mb-5">
          <header>
            {title && (
              <h3 className="h3" data-testid="PagePerson-title" {...sidekicker('Title')}>
                <TradeMark>{title}</TradeMark>
              </h3>
            )}
            {fullName && (
              <h1
                data-testid="PagePerson-fullName"
                className={cx(
                  'h2 my-2',
                  lang === 'cn' && styles.titleCn,
                  lang === 'hk' && styles.titleHk
                )}
                {...sidekicker('Full Name')}
              >
                <TradeMark>{fullName}</TradeMark>
              </h1>
            )}
            {image && (
              <figure className={cx(styles.image, 'mt-2')}>
                <Image image={image} testId="PagePerson-image" {...sidekicker('Image')} />
              </figure>
            )}
          </header>
          {bio && (
            <section className={cx(styles.richText, 'mt-5 mb-1 pt-5 pb-0')} {...sidekicker('Bio')}>
              <RichTextParser
                document={bio}
                _id={_id}
                _contentTypeId={_contentTypeId}
                internalTitle={fullName}
              />
              <div style={{ clear: 'both' }} />
            </section>
          )}
          {highResImage && (
            <div className="mb-5">
              <ElementLink
                href={highResImage.url}
                linkText={`${fullName.toUpperCase()} HEADSHOT`}
                // eslint-disable-next-line react/style-prop-object
                style="dark link"
                icon="download"
                isModal={false}
                download
                target="_blank"
                {...sidekicker('High Res Image')}
              />
            </div>
          )}
        </article>
      </div>
    </ErrorBoundary>
  );
}
PagePerson.propTypes = PagePersonPropTypes;

PagePerson.defaultProps = {
  fullName: null,
  title: null,
  image: null,
  bio: null,
  highResImage: null
};

export default PagePerson;
