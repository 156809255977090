import PropTypes from 'prop-types';
import { ImagePropTypes } from '../Image';
import PageBlogPropTypes from '../PageBlog/PageBlog.types';
import PageNewsPropTypes from '../PageNews/PageNews.types';
import { PagePersonPropTypes } from '../PagePerson';
import PageRecipePropTypes from '../PageRecipe/PageRecipe.types';

export const CardPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  cardStyle: PropTypes.oneOf([
    'Blog Post',
    'EmbeddedVideo',
    'Horizontal',
    'Icon',
    'Info',
    'Media',
    'News',
    'Product / Medium',
    'Product / Small',
    'Profile',
    'Promo',
    'Reward',
    'Round',
    'RoundCta',
    'Save / Share',
    'Side CTA',
    'Speaker',
    'Text / Link',
    'Text',
    'Timeline'
  ]).isRequired,
  linkedContent: PropTypes.oneOfType([
    PropTypes.shape(PageBlogPropTypes),
    PropTypes.shape(PageNewsPropTypes),
    PropTypes.shape(PagePersonPropTypes),
    PropTypes.shape(PageRecipePropTypes)
  ]),
  headerText: PropTypes.string,
  subhead: PropTypes.string,
  supportingText: PropTypes.string,
  media: PropTypes.shape(ImagePropTypes),
  videoStillImage: PropTypes.shape(ImagePropTypes),
  url: PropTypes.string,
  variant: PropTypes.oneOf(['', 'Image Left', 'Image Right', 'horizontal', 'vertical']),
  imageType: PropTypes.oneOf(['', 'Circle']),
  className: PropTypes.string,
  colorScheme: PropTypes.oneOf(['Default', 'Dark', 'Light']),
  ratioHorizontal: PropTypes.number,
  ratioVertical: PropTypes.number
};

export default {
  ...CardPropTypes
};
