import PropTypes from 'prop-types';
import { CardPropTypes } from '../Card';
import PageRecipePropTypes from '../PageRecipe/PageRecipe.types';
import { BannerFullSizePropTypes } from '../BannerFullSize';
import ModuleFormPropTypes from '../ModuleForm/ModuleFormPropTypes';

export const BlogPropTypes = {
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  image: PropTypes.object,
  byline: PropTypes.string,
  body: PropTypes.object
};

export const TagsTopicPropTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string,
  excludeFromList: PropTypes.bool,
  relatedItemsOn: PropTypes.bool,
  title: PropTypes.string,
  eyebrow: PropTypes.string,
  image: PropTypes.object,
  byline: PropTypes.string,
  summary: PropTypes.string,
  body: PropTypes.object,
  relatedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CardPropTypes),
      PropTypes.shape(PageRecipePropTypes),
      PropTypes.shape(BlogPropTypes)
    ])
  ),
  listStyle: PropTypes.string,
  tagsTopic: PropTypes.arrayOf(TagsTopicPropTypes),
  minutes: PropTypes.number,
  bottomModules: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(BannerFullSizePropTypes),
      PropTypes.shape(ModuleFormPropTypes)
    ])
  )
};
