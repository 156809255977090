/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import dynamic from 'next/dynamic';
import ErrorBoundary from '../ErrorBoundary';
import ElementCardPropTypes from './ElementCardPropTypes';

const ModuleText = dynamic(() => import('../ModuleText'));
const ModuleHorizontal = dynamic(() => import('../ModuleHorizontal'));
const IconCard = dynamic(() => import('./IconCard'));
const InfoCard = dynamic(() => import('./InfoCard'));
const NewsCard = dynamic(() => import('./NewsCard'));
const MediaCard = dynamic(() => import('./MediaCard'));
const ProfileCard = dynamic(() => import('./ProfileCard'));
const PromoCard = dynamic(() => import('./PromoCard'));
const RoundCard = dynamic(() => import('./RoundCard'));
const RoundCtaCard = dynamic(() => import('./RoundCtaCard'));
const SaveShareCard = dynamic(() => import('./SaveShareCard'));
const TimelineCard = dynamic(() => import('./TimelineCard'));
const EmbeddedVideoCard = dynamic(() => import('./EmbeddedVideoCard'));
const SideCtaCard = dynamic(() => import('./SideCtaCard'));
const BlogPostCard = dynamic(() => import('../../components-v2/BlogPostCard'));
const RewardCard = dynamic(() => import('./RewardCard'));
const ProductMediumCard = dynamic(() => import('../../components-v2/ProductMediumCard'));
const ProductSmallCard = dynamic(() => import('../../components-v2/ProductSmallCard'));
const SpeakerCard = dynamic(() => import('../../components-v2/SpeakerCard'));

const mappings = {
  'Horizontal': ModuleHorizontal,
  'Text': ModuleText,
  'EmbeddedVideo': EmbeddedVideoCard,
  'Info': InfoCard,
  'Media': MediaCard,
  'Icon': IconCard,
  'Text / Link': NewsCard,
  'Profile': ProfileCard,
  'Promo': PromoCard,
  'Round': RoundCard,
  'RoundCta': RoundCtaCard,
  'Save / Share': SaveShareCard,
  'Timeline': TimelineCard,
  'Side CTA': SideCtaCard,
  'Blog Post': BlogPostCard,
  'Reward': RewardCard,
  'Product / Medium': ProductMediumCard,
  'Product / Small': ProductSmallCard,
  'Speaker': SpeakerCard
};

function ElementCard(props) {
  const { cardStyle } = props;
  const Card = mappings[cardStyle];
  if (!Card) {
    // eslint-disable-next-line no-console
    console.info(`Did not find mapping for Card Type ${cardStyle}. Using default Info style`);
    return (
      <ErrorBoundary>
        <InfoCard {...props} />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary>
      <Card {...props} />
    </ErrorBoundary>
  );
}
ElementCard.propTypes = ElementCardPropTypes;
ElementCard.defaultProps = {
  linkedContent: null,
  headerText: null,
  subhead: null,
  supportingText: null,
  media: null,
  url: null,
  variant: null,
  className: null,
  colorScheme: null,
  ratioHorizontal: null,
  ratioVertical: null
};
export default ElementCard;
